import ScanCoupon from 'views/ScanCoupon';
import Home from 'views/Home';
import { FunctionComponent } from 'react';
import LogIn from 'views/LogIn';
import Card from 'views/Card';
import Coupon from 'views/Coupon';
import GiveStamps from 'views/GiveStamps';

export type Route = {
  title: string;
  path: string;
  component: FunctionComponent;
  exact: boolean;
  private: boolean;
};

const routes: Route[] = [
  {
    title: 'Home',
    path: '/',
    component: Home,
    exact: true,
    private: true,
  },
  {
    title: 'Scan Coupon',
    path: '/scan-coupon',
    component: ScanCoupon,
    exact: true,
    private: true,
  },
  {
    title: 'Show QR',
    path: '/give-stamps',
    component: GiveStamps,
    exact: true,
    private: true,
  },
  {
    title: 'Log In',
    path: '/log-in',
    component: LogIn,
    exact: true,
    private: false,
  },
  {
    title: 'Card',
    path: '/cards/:cardId',
    component: Card,
    exact: true,
    private: true,
  },
  {
    title: 'Coupon',
    path: '/coupon/:id',
    component: Coupon,
    exact: true,
    private: true,
  },
];
export default routes;
