import React, { FC } from 'react';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  CoffeeOutlined, SmileFilled, SmileOutlined, SmileTwoTone,
} from '@ant-design/icons';
import { Col, Row } from 'antd';

interface Props {
  repetitions: number;
  checks: unknown[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Checks: FC<Props> = (props: Props) => {
  const { repetitions, checks } = props;

  return (
    <Row style={{
      margin: '1.5vh auto 1.5vh auto',
      padding: '4%',
      border: '2px dashed #07668c',
    }}
    >
      <Col style={{ width: '100%' }}>
        {Array.apply('', Array(repetitions)).map((v, index) => {
          const Comp = CoffeeOutlined;
          const color = checks.length > index ? '#f2a413' : '#05374255';

          return (
            <Comp
            // eslint-disable-next-line react/no-array-index-key
              key={index}
              twoToneColor={color}
              style={{
                fontSize: '40px',
                margin: '10px',
                color,
              }}
            />
          );
        })}
      </Col>
    </Row>
  );
};

export default Checks;
