import { CopyOutlined } from '@ant-design/icons';
import {
  Row, Col, notification, Button,
} from 'antd';
import useAssignedCheckSubscription from 'apollo/resolvers/check/assignSubscription';
import useCreateCheckMutation from 'apollo/resolvers/check/create';
import LoadingModal from 'components/generalUI/LoadingModal';
import QRCode from 'components/generalUI/QRCode';
import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as texts from '../../assets/texts/giveStamps';

const GiveStamps
: FC = () => {
  const history = useHistory();
  const [createCheck, { data, loading }] = useCreateCheckMutation();
  const checkId = data?.createCheck.id;
  const companyName = data?.createCheck.cardTemplate.company.name;
  const { data: updateData } = useAssignedCheckSubscription(checkId);
  const assignedCardId = updateData?.assignedCheck.card?.id;

  useEffect(() => {
    createCheck();
  }, [createCheck]);

  useEffect(() => {
    if (assignedCardId) {
      notification.success({
        message: '¡Tarjeta marcada con éxito!',
        duration: 10,
        placement: 'bottomRight',
      });
      history.push(`/cards/${assignedCardId}`);
    }
  }, [assignedCardId, history]);

  return (
    <Row style={{ width: '90%', margin: 'auto' }}>
      <LoadingModal visible={loading} />
      {companyName
      && (
      <Col span={24} md={12}>
        <h1>{texts.title(companyName)}</h1>
        <h3>{texts.subTitle}</h3>
        <h4 style={{ textAlign: 'center' }}>{texts.body}</h4>
        { checkId
        && (
          <>
            <QRCode payload={`https://volbi.app/check/${checkId}`} />
            <Col>
              <h4>{texts.copyLink}</h4>
              <Button
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(`https://volbi.app/check/${checkId}`);
                }}
              >
                {texts.copyLinkBtn}

              </Button>
            </Col>
          </>
        )}
      </Col>
      )}
    </Row>
  );
};
export default GiveStamps;
